import { unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "site-errors" }
const _hoisted_2 = {
  key: 0,
  class: "browser-error",
  role: "alert"
}
const _hoisted_3 = {
  class: "rotate-phone",
  role: "alert"
}

import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';


    
export default {
  __name: 'StarbucksErrorMessages',
  setup(__props) {

    const store = useStore();
    const { vT } = useI18next('error');

    const incompatibleBrowser = ref(false);

    onMounted(() => {
        try {
            // Check if the browser is Internet Explorer
            if (navigator.userAgent.includes('MSIE') || navigator.userAgent.includes('Trident/')) {
                incompatibleBrowser.value = true;

                // Check for the existence of $urchin
                if (window.$urchin) {
                    window.$urchin.trackEvent(
                        'Incompatible Browser Error',
                        'View',
                        store.state.profile.id,
                    );
                }
            }
        }
        catch (error) {
            console.error('Error checking for incompatible browser or tracking event:', error);
        }
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (incompatibleBrowser.value)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
          [_unref(vT), 'error:browser_error']
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
      [_unref(vT), 'error:rotate_phone']
    ])
  ]))
}
}

}