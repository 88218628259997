
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';


function normalizePrizeId (prizeId) {
    return prizeId.replaceAll('-', '').toLowerCase();
}


const getDefaultState = () => reactive({
    awards: null,
    prizeWon: null,
    awardData: null,
    collectAndWin: {},
    instantWin: [],
    sweeps: 0,
});

const state = getDefaultState();

const getters = {
    getPieceCountById: (state) => (pieceId) => {
        const tierId = `collect_tier${pieceId.slice(4, -2)}`;
        return (state.collectAndWin[tierId]) ? state.collectAndWin[tierId][pieceId] : 0;
    },

    getPieceCountForTier: (state) => (tierId) =>
        (state.collectAndWin[tierId] ? Object.keys(state.collectAndWin[tierId]).length : 0),

    isFirstPlay (state, getters, rootState, rootGetters) {
        return getters.ownedPieces.length === 0 &&
            state.instantWin.length === 0 &&
            state.sweeps === 0;
    },

    isTierComplete: (state) => (tierId) =>
        (state.collectAndWin[tierId] ? Object.keys(state.collectAndWin[tierId]).length === 3 : false),

    ownedPieces (state) {
        const pieces = [];

        for (const tierId in state.collectAndWin) {
            for (const pieceId in state.collectAndWin[tierId]) {
                if (state.collectAndWin[tierId][pieceId]) {
                    pieces.push(pieceId);
                }
            }
        }

        return pieces;
    },
};

const mutations = {
    updateAwards (state, awards) {
        state.awards = awards;
    },

    updatePrizeWon (state, prizeWon) {
        state.prizeWon = prizeWon;
    },

    setAwardData (state, data) {
        state.awardData = data;
    },

    clearAwardData (state) {
        state.awardData = null;
    },

    updateBoardState (state, { boardState }) {
        // Collect and Win tiers
        state.collectAndWin = { ...boardState.pieces } || {};

        // Instant Win prizes
        state.instantWin = [];
        for (const prizeId of boardState.prizeIds) {
            state.instantWin.push(normalizePrizeId(prizeId));
        }

        state.sweeps = boardState.sweepsCount;

        state.loaded = true;
    },

    incrementPieceCountById (state, { pieceId }) {
        let count = 0;

        const tier = `collect_tier${pieceId.slice(4, -2)}`;
        if (pieceId in state.collectAndWin[tier]) {
            count = state.collectAndWin[tier][pieceId];
        }

        state.collectAndWin[tier][pieceId] = count + 1;
    },

    incrementSweeps (state) {
        state.sweeps += 1;
    },

    setPieceCountById (state, { pieceId, count }) {
        const tier = `collect_tier${pieceId.slice(4, -2)}`;
        state.collectAndWin[tier][pieceId] = count;
    },

    addInstantWinPrize (state, { id: prizeId }) {
        state.instantWin.push(normalizePrizeId(prizeId));
    },
};

const actions = {
    async getAwards ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'awards',
        });
    },

    async awardEvent ({ dispatch }, { event }) {
        const data = {};

        return dispatch('makeCall', {
            endpoint: `awards/events/${encodeURIComponent(event)}:award`,
            data,
        });
    },

    async loadBoardState ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/awards/boardState`);
            const boardState = response.data;

            commit('updateBoardState', { boardState });
        }
        catch (error) {
            console.error('could not load board state', error);
            throw error;
        }
    },

    async play ({ commit, dispatch, rootState, state }) {
        const data = {};

        const response = await dispatch('makeCall', {
            endpoint: 'awards/:play',
            data,
        });

        const {
            pieceWon,
            playsRemaining,
            prizeWon,
            sweepsWon,
            awards,
        } = response.data;

        if (prizeWon?.id) {
            prizeWon.id = normalizePrizeId(prizeWon.id);
        }

        commit('setAwardData', { pieceWon, playsRemaining, prizeWon, sweepsWon, awards });

        return {
            pieceWon,
            playsRemaining,
            prizeWon,
            sweepsWon,
            awards,
        };
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            if (err.response?.status === 429) {
                // User was only limited, carry on
                ({ response } = err);
            }
            else {
                console.error(
                    `error making ${endpoint} call`,
                    err.message,
                    err,
                );

                throw err;
            }
        }

        if (response.data?.awards !== undefined) {
            commit('updateAwards', response.data.awards);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
