import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M8.243 8.03a.999.999 0 0 0-1.213.743 1.023 1.023 0 0 0 .728 1.239c1.05.268 2.119.435 3.192.5a19.733 19.733 0 0 1-2.237 7.671l-.191.362c-.265.5-.08 1.106.41 1.351.492.246 1.105.04 1.37-.462l.191-.361A22.045 22.045 0 0 0 12 15.493a22.063 22.063 0 0 0 1.507 3.58l.191.361c.265.501.878.708 1.37.462.491-.245.675-.85.41-1.35l-.19-.363a19.733 19.733 0 0 1-2.238-7.67 17.154 17.154 0 0 0 3.193-.501c.536-.137.861-.691.727-1.239a.999.999 0 0 0-1.212-.743c-2.467.63-5.048.63-7.515 0z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M12 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z",
      fill: "currentColor"
    }, null, -1)
  ])))
}