import store from '../store/index';

const SurveyPage = async () => import(
    /* webpackChunkName: 'survey' */
    './../views/SurveyPage.vue'
);

export default [
    {
        path: '/', redirect: (to) => {
            if (store.getters['profile/loggedIn']) {
                return '/survey';
            }
            return '/landing';
        },
        meta: { activeInPhase: ['survey'], activeInSurvey: true },
    },
    {
        path: '/survey',
        component: SurveyPage,
        meta: { activeInPhase: ['survey'], activeInSurvey: true },
    },
];
