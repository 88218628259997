import { camelCase, startCase } from 'lodash';
import { i18next } from './i18n';

import { logGdsMessage, trackGdsPageView } from './plugins/GdsPlugin';

import store from './store';

const
    debugGlobalObject = false, // Toggles console messages about the global object.
    isLocalDev = document.location.href.match(/localhost:808/),
    { appInfo } = window.uo_data,
    // These exceptions represent pages and modals for which
    // the page view:
    //   1) Must be called from within the Vue file for some reason.
    //   2) Should not be called at all.
    // Please use these exceptions sparingly. (:
    passivePageViewExceptions = new Set(['dashboard']),
    passiveModalPageViewExceptions = new Set([
        'pieceWin',
        'prizeWin',
        'sweepsWin',
        'tierDetails',
        'sessionExpirationModal',
    ]),

    // Martech has asked that we always re-fire the parent pageView once the modal is closed.
    // This gives us a place to add pages which may need to be called from various locations,
    // such as multiple types of modals. The latest viewed page in this Set will be added to
    // the gds Vuex store under pageViewData.
    globallyCalledPageViews = new Set(['dashboard']);

appInfo.csid = undefined; // Need to check against this later to ensure it's only set once

// Update on state changes
store.subscribe(async (mutation, state) => {
    try {
        // Set appInfo values
        appInfo.pageURL = document.location.href;
        appInfo.screenName = getGdsPageTemplateName(state.ui);
        appInfo.appLanguage =
            state.app.userLanguage ? (state.app.userLanguage).toUpperCase() : '';
        appInfo.appName = i18next.t('gdsText:globalObject.appInfo.appName');
        appInfo.appRegion = state.app.userCountry;

        if (state.profile.csid && !appInfo.csid) {
            appInfo.csid = state.profile.csid;
            logInToAnalytics(state.profile.csid);
        }

        appInfo.environment = getGdsEnvironment();

        // pageViews
        if (mutation.type === 'ui/pageEnter') {
            const
                dimensions = {},
                hash = document.location.hash.replace('#/', ''),
                camelCasedHash = camelCase(hash);
            let pageName = camelCase(mutation.payload.pageName) || camelCasedHash;

            logGdsMessage({
                item: `Working pageName is: ${pageName}`,
                message: '',
                trackingMethod: 'pageView',
                type: 'info',
            });

            if (!mutation.payload.pageName) {
                logGdsMessage({
                    item: `pageView for ${hash}`,
                    // The following spaces after the newline are intentional.
                    message: 'No pageName specified for this template.\n      Derived pageName from hash value.',
                    trackingMethod: 'pageView',
                    type: 'info',
                });
            }
            else if (pageName === 'error') {
                if (mutation.payload.error === 'ineligible_partner' || hash.includes('partner')) {
                    dimensions.screenDetail = i18next.t('gdsText:screenDetails.errorPartner');
                }
                else if (
                    hash.includes('country') ||
                    hash.includes('loyalty') ||
                    hash.includes('email') ||
                    hash.includes('oauth')
                ) {
                    dimensions.screenDetail = i18next.t(`gdsText:screenDetails.${camelCasedHash}`);
                }
                else {
                    dimensions.screenDetail = i18next.t('gdsText:screenDetails.errorGeneric');
                }
            }
            else if (pageName === 'landing') {
                if (state.app.phase === 'expired') {
                    pageName = 'expired';
                }
                else if (state.app.phase === 'pre-launch') {
                    pageName = 'preLaunch';
                }
                else if (state.app.phase === 'survey') {
                    pageName = 'postGameSurvey';
                }
            }

            // Ensure screenDetail is either initialized or preserved
            dimensions.screenDetail = dimensions.screenDetail || i18next.t(`gdsText:screenDetails.${pageName}`);

            // If this pageView can be fired from other components,
            // store the data for later but don't fire the view.
            if (globallyCalledPageViews.has(pageName)) {
                store.dispatch('gds/updatePageViewData', {
                    pageName: `${pageName}`,
                    category: i18next.t(`gdsText:templateNames.${pageName}`),
                    dimensions,
                });
            }

            // If this is not a special case, fire the page view
            if (!passivePageViewExceptions.has(pageName)) {
                trackGdsPageView({
                    category: i18next.t(`gdsText:templateNames.${pageName}`),
                    dimensions,
                });
            }
        }
        // "pageViews" for dialogs
        else if (mutation.type === 'ui/dialogOpen') {
            const
                dimensions = {},
                hash = document.location.hash.replace('#/', ''),
                camelCasedHash = camelCase(hash),
                gdsDialogName = camelCase(mutation.payload.dialogName) ||
                                mutation.payload.challengeId ||
                                camelCasedHash;

            logGdsMessage({
                item: `Working dialogName is: ${gdsDialogName}`,
                message: '',
                trackingMethod: 'pageView',
                type: 'info',
            });

            if (!mutation.payload.dialogName && !mutation.payload.challengeId) {
                logGdsMessage({
                    item: `Dialog pageView for ${hash || gdsDialogName}`,
                    message: '\n[GDS] No dialogName specified for this template.\n[GDS] Derived dialogName from hash value.',
                    trackingMethod: 'pageView',
                    type: 'info',
                });
            }

            dimensions.screenDetail = i18next.t(`gdsText:screenDetails.${gdsDialogName}`);

            if (!passiveModalPageViewExceptions.has(gdsDialogName)) {
                trackGdsPageView({
                    category: i18next.t(`gdsText:templateNames.${gdsDialogName}`),
                    dimensions,
                });
            }
        }

        if (debugGlobalObject && isLocalDev) {
            showGlobalObjectConsoleLog();
        }
    }
    catch (error) {
        console.log(error);
    }
});

// GDS Helper functions
function getGdsEnvironment () {
    const { host } = document.location;
    let environmentCopyNode = 'live';
    if (host.includes('localhost:808') || host.includes('d0rs') || host.includes('promodev')) {
        environmentCopyNode = 'development';
    }
    else if (host.includes('test') || host.includes('t0rs')) {
        environmentCopyNode = 'test';
    }
    else if (host.includes('review') || host.includes('r0rs')) {
        environmentCopyNode = 'review';
    }
    return i18next.t(`gdsText:globalObject.appInfo.environments.${environmentCopyNode}`);
}

function getGdsPageTemplateName (stateUi) {
    const hash = document.location.hash.replace('#/', '');

    if (stateUi.modalName === 'win') {
        return i18next.t('gdsText:templateNames.gameResults');

    }
    else {
        const gdsTemplateName =
            i18next.t(`gdsText:templateNames.${hash}`) ||
            i18next.t(`gdsText:templateNames.${stateUi.pageName}`) ||
            startCase(stateUi.pageName);
        return gdsTemplateName;
    }
}

function logInToAnalytics (csid) {
    if (isLocalDev) {
        logGdsMessage({
            item: `Bypassing Analytics Login\n[GDS] For csid: ${csid}`,
            // The following spaces after the newline are intentional.
            message: '\n[GDS] Skipping login attempt on localhost.',
            trackingMethod: 'login',
            type: 'info',
        });
    }
    else {
        try {
            window._analytics.login(csid);
        }
        catch (error) {
            console.error(`[GDS] Unable to log in to _analytics with csid ${csid}`);
        }
    }
}

function showGlobalObjectConsoleLog () {
    const consoleStyle = 'color: orange; font-family: "Courier"; font-weight: 400; font-size: 13px;';
    console.info('%c[GDS] Updated the global object.', consoleStyle);
    console.log('%c[GDS] window.uo_data.appInfo', consoleStyle, window.uo_data.appInfo);
    console.log('%c[GDS] window.uo_data.user', consoleStyle, window.uo_data.user);
    if (window.uo_data.experiment) {
        console.log('%c[GDS] window.uo_data.experiment', consoleStyle, window.uo_data.experiment);
    }
}
