import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "main-section",
  role: "main"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")
  const _component_FreePlayRegisterationManager = _resolveComponent("FreePlayRegisterationManager")
  const _component_StarbucksErrorMessages = _resolveComponent("StarbucksErrorMessages")
  const _component_TheFooter = _resolveComponent("TheFooter")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheHeader),
    _createElementVNode("main", _hoisted_1, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      })),
      ($options.showLoadingScreen)
        ? (_openBlock(), _createBlock(_component_LoadingScreen, {
            key: 0,
            onAssetsLoaded: _cache[0] || (_cache[0] = $event => ($data.assetsLoaded = true))
          }))
        : _createCommentVNode("", true),
      ($data.assetsLoaded)
        ? (_openBlock(), _createBlock(_component_FreePlayRegisterationManager, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_StarbucksErrorMessages)
    ]),
    _withDirectives(_createVNode(_component_TheFooter, null, null, 512), [
      [_vShow, $options.shouldShowFooter]
    ])
  ], 64))
}