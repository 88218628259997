import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import Cookies from 'js-cookie';
    import { nextTick, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import FreePlayRegisterationDialog from '@public/js/dialogs/FreePlayRegisterationDialog.vue';
    import DoublePlayManager from '@components/DoublePlayDayManager.vue';
    import FreePlayFridaysManager from '@components/FreePlayFridaysManager.vue';

    // Setup
    
export default {
  __name: 'FreePlayRegisterationManager',
  setup(__props) {

    const store = useStore();

    // Data
    const dialog = ref();
    const showOtherDialog = ref(false);
    const shouldShowDialog = ref(false);

    // Methods
    function handleDialogOpenChange (isOpen) {
        showOtherDialog.value = !isOpen;
    }

    function openFreePlayRegisterationDialog () {
        // Show FreePlay Register Dialog if necessary
        const freePlayRegisterationCookie = `${store.state.app.name}:freePlay`;
        if (Cookies.get(freePlayRegisterationCookie)) {
            shouldShowDialog.value = true;
            nextTick(() => {
                dialog.value.showModal();
            });
            Cookies.remove(freePlayRegisterationCookie);
        }
        else {
            showOtherDialog.value = true;
        }
    }

    // Lifecycle
    onMounted(() => {
        openFreePlayRegisterationDialog();
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (shouldShowDialog.value)
      ? (_openBlock(), _createBlock(FreePlayRegisterationDialog, {
          key: 0,
          ref_key: "dialog",
          ref: dialog,
          "onUpdate:isOpen": handleDialogOpenChange
        }, null, 512))
      : _createCommentVNode("", true),
    (showOtherDialog.value)
      ? (_openBlock(), _createBlock(FreePlayFridaysManager, { key: 1 }))
      : _createCommentVNode("", true),
    (showOtherDialog.value)
      ? (_openBlock(), _createBlock(DoublePlayManager, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

}