
    import { mapState, mapGetters, mapActions } from 'vuex';

    import { useI18next } from '@composables/i18next';


    import TheFooter from '@components/TheFooter.vue';
    import TheHeader from '@components/TheHeader.vue';
    import LoadingScreen from '@components/LoadingScreen.vue';
    import StarbucksErrorMessages from '@components/StarbucksErrorMessages.vue';
    import FreePlayRegisterationManager from './components/FreePlayRegisterationManager.vue';

    import webAnalytics from './plugins/WebAnalytics';
    import logClientError from './client-error';

    export default {
        name: 'App',

        components: {
            TheFooter,
            TheHeader,
            LoadingScreen,
            FreePlayRegisterationManager,
            StarbucksErrorMessages,
        },

        provide: {
            $webAnalytics: webAnalytics,
        },

        setup () {
            const { t, vT } = useI18next(['global', 'cookie_gate']);
            return { t, vT };
        },

        data () {
            return {
                assetsLoaded: false,
            };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            shouldShowFooter () {
                return this.ui.pageName != 'faq';
            },

            showLoadingScreen () {
                return this.loggedIn && !this.assetsLoaded && this.ui.pageName != 'faq';
            },
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured (err) {
            this.$router.push('/error');
            // Forward fatal Vue component errors, i.e. those that caused us to display
            // the error page here, to './client-error' and its server-side logging.
            logClientError(err, { fatal: true });
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };
