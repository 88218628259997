import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page general-page error-page" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import { useI18next } from '@composables/i18next';
    import { useBasePage } from '@composables/page';


    // Setup
    
export default {
  __name: 'ErrorPage',
  setup(__props) {

    useBasePage('error');
    const { t, vT } = useI18next(['error']);

return (_ctx, _cache) => {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      innerHTML: _unref(t)([`error:${_ctx.error}.headline_copy`, 'error:default.headline_copy'])
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      innerHTML: _unref(t)([`error:${_ctx.error}.body_copy`, 'error:default.body_copy'])
    }, null, 8, _hoisted_3),
    _createVNode(_component_RouterLink, {
      to: "/",
      class: "button large color-2"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _unref(t)([`error:${_ctx.error}.button_copy`, 'error:default.button_copy'])
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    })
  ]))
}
}

}