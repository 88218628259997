import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "language-selector" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';

    import CircleCheckIcon from '@components/icons/CircleCheckIcon.vue';


    
export default {
  __name: 'LanguageSelector',
  setup(__props) {

    const store = useStore();
    const { vT } = useI18next('global', { keyPrefix: 'languageSelector' });


    function getUrlForLocale (locale) {
        let url = store.state.app.siteURL;
        const { userCountry } = store.state.app;

        const vanityDomains = ['starbucksforlife', 'starbucksavie'];

        if (vanityDomains.some((domain) => url.includes(domain))) {
            return locale.includes('fr') ?
                'https://www.starbucksavie.ca' :
                'https://www.starbucksforlife.ca';
        }

        if (url.includes('starbucks')) {
            const baseDomain = 'starbucks-holiday';
            const subDomainRegex = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
            const subDomain = url.match(subDomainRegex)[1];
            const suffix = locale.includes('fr') ? '-frca' : userCountry.includes('CA') ? '-ca' : '';
            url = url.replace(subDomain, `${baseDomain}${suffix}`);
        }
        else {
            const combinedLocale = (locale === 'en') ? [locale, userCountry].join('-') : locale;
            url = `${url}?locale=${combinedLocale}`;
        }

        return url;
    }

    async function handleLocaleClick () {
        try {
            window._analytics.logout();
        }
        catch (error) {
            console.error('[GDS] Logout unsuccessful');
        }
    }

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      (_unref(store).state.app.userLanguage === 'en')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(CircleCheckIcon, {
              role: "presentation",
              alt: ""
            }),
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'en']
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: getUrlForLocale('en'),
            onClick: handleLocaleClick
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'en']
            ])
          ], 8, _hoisted_2))
    ]),
    _createElementVNode("li", null, [
      (_unref(store).state.app.userLanguage === 'fr')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(CircleCheckIcon, {
              role: "presentation",
              alt: ""
            }),
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'fr']
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: getUrlForLocale('fr-CA'),
            onClick: handleLocaleClick
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'fr']
            ])
          ], 8, _hoisted_3))
    ])
  ]))
}
}

}