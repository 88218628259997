import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M12.664 18.563a1 1 0 0 1-1.32.083l-.095-.083-6.69-6.707 6.69-6.707a1 1 0 0 1 1.32-.083l.095.083a1 1 0 0 1 .083 1.32l-.083.094-4.304 4.293h10.082a1 1 0 1 1 0 2H8.344l4.32 4.293a1 1 0 0 1 .083 1.32l-.083.094z",
      fill: "currentColor"
    }, null, -1)
  ])))
}