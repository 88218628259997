
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DashboardPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/DashboardPage.vue'
    ),
    PrizesPage = async () => import(
        /* webpackChunkName: 'prizes' */
        '../views/PrizesPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (store.getters['profile/loggedIn']) {
            return '/dashboard';
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/dashboard',  component: DashboardPage },
    { path: '/prizes', component: PrizesPage },
];
