import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "the-footer" }
const _hoisted_2 = { class: "footer-inner" }
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "footer-links" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["href", "aria-label"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "disclaimer" }
const _hoisted_12 = {
  key: 2,
  class: "disclaimer"
}
const _hoisted_13 = { class: "disclaimer" }
const _hoisted_14 = {
  key: 3,
  class: "artificial-tests"
}
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["src"]

import { computed } from 'vue';
    import { useStore } from 'vuex';

    import { useI18next } from '@composables/i18next';

    import LanguageSelector from '@components/LanguageSelector.vue';

    import { resolveWebpackI18nAsset } from '../plugins/I18nAssetsPlugin';
    import { openPopup } from '../popup';


    // Setup
    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();
    const { t, vT } = useI18next(['footer', 'links', 'global']);


    // Computed
    const faqUrl = computed(() => {
        if (store.state.app?.siteURL?.includes('localhost')) {
            return `/faq/?locale=${store.state.app.locale}`;
        }

        return '/faq';
    });

    const footerDecorationImage = computed(() =>
        require(`../../img/footer-decoration-${store.getters['ui/isAnimationEnabled'] ? 'animated' : 'static'}.webp`));

    const loggedIn = computed(() => store.getters['profile/loggedIn']);

    const showDisclaimer = computed(() => ['landing'].includes(store.state.ui.pageName));


    // Methods
    const logOut = () => store.dispatch('profile/logOut');

return (_ctx, _cache) => {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "footer-wave" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "footer-decoration",
        alt: _unref(t)('footerAnimationAriaLabel'),
        src: footerDecorationImage.value
      }, null, 8, _hoisted_3),
      _createElementVNode("img", {
        class: "rewards-logo",
        alt: _unref(t)('logoAriaLabel'),
        src: _unref(resolveWebpackI18nAsset)('img/sr-logo.svg')
      }, null, 8, _hoisted_4),
      (_unref(store).state.app.userCountry === 'CA')
        ? (_openBlock(), _createBlock(LanguageSelector, { key: 0 }))
        : _createCommentVNode("", true),
      _cache[3] || (_cache[3] = _createElementVNode("div", { id: "teconsent" }, null, -1)),
      _createElementVNode("nav", _hoisted_5, [
        _createElementVNode("ul", null, [
          _withDirectives(_createElementVNode("li", null, null, 512), [
            [_unref(vT), { key: 'links:rules', tb2Url: _unref(store).state.app.tb2URL }]
          ]),
          _withDirectives(_createElementVNode("li", null, null, 512), [
            [_unref(vT), 'links:privacy']
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("li", { class: "break small-only" }, null, -1)),
          _createElementVNode("li", null, [
            _withDirectives(_createElementVNode("a", {
              href: _unref(t)('links:client_terms_url'),
              target: "_blank"
            }, null, 8, _hoisted_6), [
              [_unref(vT), 'links:client_terms_copy']
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: faqUrl.value,
              onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]))
            }, _toDisplayString(_unref(t)('links:faq_copy')), 9, _hoisted_7)
          ]),
          (loggedIn.value)
            ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                _withDirectives(_createElementVNode("a", {
                  href: _unref(store).state.app.sbuxLogoutURL,
                  class: "btn_logout",
                  "data-e2e": "footer-logout",
                  "aria-label": _unref(t)('links:log_out'),
                  onClick: logOut
                }, null, 8, _hoisted_9), [
                  [_unref(vT), 'links:log_out']
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (loggedIn.value && _unref(store).state.app.phase !== 'survey')
        ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
            _createTextVNode(_toDisplayString(_unref(t)('footer:oamoe_copy')) + " ", 1),
            _createVNode(_component_RouterLink, { to: "/oamoe" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('footer:oamoe_link_copy')), 1)
              ]),
              _: 1
            }),
            _cache[2] || (_cache[2] = _createTextVNode(". "))
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("p", _hoisted_11, null, 512), [
        [_unref(vT), { key: 'footer:disclaimer1', tb2Url: _unref(store).state.app.tb2URL }]
      ]),
      (showDisclaimer.value)
        ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_12, null, 512)), [
            [_unref(vT), 'footer:disclaimer2']
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("p", _hoisted_13, null, 512), [
        [_unref(vT), { key: 'footer:disclaimer3', tb2Url: _unref(store).state.app.tb2URL }]
      ]),
      (_unref(store).state.app.isAutomatedTest)
        ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
            _createElementVNode("span", {
              class: "test-copy",
              textContent: _toDisplayString(_unref(t)('testNamespace:testKey'))
            }, null, 8, _hoisted_15),
            _createElementVNode("img", {
              class: "language-flag",
              alt: "Language flag",
              src: _ctx.$i18nAsset('img/flag.svg'),
              style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
            }, null, 8, _hoisted_16)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

}