import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-screen" }
const _hoisted_2 = ["src"]

import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex';

    
export default {
  __name: 'LoadingScreen',
  emits: ['assets-loaded'],
  setup(__props, { emit: __emit }) {

    const emit = __emit;

    const store = useStore();

    const assetList = ref([]);
    const loadingComplete = ref(false);
    const loadingVideo = ref(null);
    const isLoading = ref(true);
    const assetsLoaded = ref(false);

    const videoBaseUrl = computed(() => `${store.getters['app/cloudFrontBaseUrl']}starbucks/holiday2024/`);

    const compileAssetList = (data) => {
        if (typeof data === 'string') {
            assetList.value.push(data);
        }
        else if (Array.isArray(data)) {
            data.forEach(asset => compileAssetList(asset));
        }
        else if (typeof data === 'object') {
            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    compileAssetList(data[key]);
                }
            }
        }
        else {
            console.error('error preloading assets: asset list contains unknown data type');
        }
    };

    const loadAsset = (path) => new Promise((resolve) => {
        const image = new Image();
        image.addEventListener('load', () => resolve());
        image.src = path;
    });

    const loadAssets = async () => {
        try {
            const response = await axios.get('/public/build/webpack-assets.json');
            compileAssetList(response.data);

            // Load all assets
            await Promise.all(assetList.value.map((element) => loadAsset(element)));

            assetsLoaded.value = true; // Mark assets as loaded
            close(); // Check if we can close
        }
        catch (error) {
            console.error('error preloading assets:', error);
            close();
        }
    };

    const close = () => {
        // Stop video before closing
        if (loadingVideo.value) {
            loadingVideo.value.pause();
            loadingVideo.value.currentTime = 0;
        }
        loadingComplete.value = true;
        isLoading.value = false;
        emit('assets-loaded');
    };

    onMounted(async () => {
        loadAssets();
    });

    onBeforeUnmount(() => {
        if (loadingVideo.value) {
            loadingVideo.value.pause();
            loadingVideo.value.currentTime = 0;
        }
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      ref_key: "loadingVideo",
      ref: loadingVideo,
      class: "loading-animation",
      alt: "loading-animation",
      autoplay: "",
      crossorigin: "anonymous",
      disablepictureinpicture: "",
      disableremoteplayback: "",
      loop: "",
      muted: "",
      playsinline: "",
      preload: ""
    }, [
      _createElementVNode("source", {
        src: `${videoBaseUrl.value}mini-loader.mp4`,
        type: "video/mp4"
      }, null, 8, _hoisted_2)
    ], 512)
  ]))
}
}

}