import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "visually-hidden" }
const _hoisted_2 = {
  key: 1,
  class: "spacer",
  "aria-hidden": "true"
}
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 3,
  class: "spacer",
  "aria-hidden": "true"
}

import { computed, onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';

    import { useI18next } from '@composables/i18next';

    import AccessibilityMenu from '@components/AccessibilityMenu.vue';
    import LeftArrowIcon from '@components/icons/LeftArrowIcon.vue';


    // Setup
    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const router = useRouter();
    const store = useStore();
    const { t, vT } = useI18next(['global', 'buttons']);

    const h1 = ref();


    // Computed
    const isLarge = computed(() => ['landing'].includes(store.state.ui.pageName));

    const showWhiteLogo = computed(() => ['dashboard'].includes(store.state.ui.pageName));

    const shouldShowBackButton = computed(() => ['prizes', 'oamoe', 'error'].includes(store.state.ui.pageName));

    const shouldShowMenu = computed(() => !['faq', 'landing', 'prizes', 'oamoe'].includes(store.state.ui.pageName));

    // Lifecycle hooks
    onMounted(() => {
        router.afterEach(() => h1.value.focus());
    });

return (_ctx, _cache) => {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("header", {
    id: "header",
    role: "banner",
    class: _normalizeClass(["the-header", { large: isLarge.value }]),
    "data-e2e": "global-header"
  }, [
    (shouldShowBackButton.value)
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          class: "button icon color-1",
          to: "/dashboard"
        }, {
          default: _withCtx(() => [
            _createVNode(LeftArrowIcon, { "aria-hidden": "true" }),
            _withDirectives(_createElementVNode("span", _hoisted_1, null, 512), [
              [_unref(vT), 'buttons:back']
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, [
      (!_unref(store).state.profile.plays_remaining && showWhiteLogo.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.$i18nAsset('img/sfl-logo-white.svg'),
            alt: _unref(t)('global:title'),
            class: "header-logo"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.$i18nAsset('img/sfl-logo.svg'),
            alt: _unref(t)('global:title'),
            class: "header-logo"
          }, null, 8, _hoisted_4))
    ], 512),
    (shouldShowMenu.value)
      ? (_openBlock(), _createBlock(AccessibilityMenu, { key: 2 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_5))
  ], 2))
}
}

}