import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { sub } from 'date-fns';
    import Cookies from 'js-cookie';
    import { nextTick, ref, watch } from 'vue';
    import { useStore } from 'vuex';

    import DoublePlayDaysDialog from '@public/js/dialogs/DoublePlayDaysDialog.vue';


    // Setup
    
export default {
  __name: 'DoublePlayDayManager',
  setup(__props) {

    const store = useStore();

    const doublePlayDayChallenges = new Set([
        'doublePlayDayMonday1',
        'doublePlayDayMonday2',
        'doublePlayDayMonday3',
        'doublePlayDayMonday4',
        'doublePlayDayMonday5',
    ]);


    // Data
    const dialog = ref();
    const shouldShowDialog = ref(false);


    // Methods
    function showDoublePlayDaysDialog (challenges) {
        const appNow = new Date(store.state.app.now);

        for (const challengeId of doublePlayDayChallenges) {
            const cookieName = `${store.state.app.name}:${challengeId}dialog-${store.state.profile.id}`;
            if (Cookies.get(cookieName)) {
                // Already viewed this dialog, go to next
                continue;
            }

            const challenge = challenges[challengeId];

            // Only showing day before
            const start = new Date(challenge?.properties?.startDate);
            const before = sub(start, { days: 1 });

            if (appNow >= before && appNow < start) {
                shouldShowDialog.value = true;
                nextTick(() => {
                    dialog.value.showModal();
                });

                Cookies.set(cookieName, 1);
                break; // Found the active one, don't check any further
            }
        }
    }

    // Watch
    watch(store.state.challenges.challenges, (value) => {
        if (value) {
            showDoublePlayDaysDialog(value);
        }
    }, { immediate: true });

return (_ctx, _cache) => {
  return (shouldShowDialog.value)
    ? (_openBlock(), _createBlock(DoublePlayDaysDialog, {
        key: 0,
        ref_key: "dialog",
        ref: dialog
      }, null, 512))
    : _createCommentVNode("", true)
}
}

}