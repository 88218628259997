import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { sub } from 'date-fns';
    import Cookies from 'js-cookie';
    import { nextTick, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import FreePlayFridaysDialog from '@public/js/dialogs/FreePlayFridaysDialog.vue';


    // Setup
    
export default {
  __name: 'FreePlayFridaysManager',
  setup(__props) {

    const store = useStore();

    const freePlayDayDates = new Set(["2024-12-06","2024-12-13","2024-12-20","2024-12-27","2025-01-03"]);

    // Data
    const dialog = ref();
    const shouldShowDialog = ref(false);


    // Methods
    function showFreePlayDialog () {
        const appNow = new Date(store.state.app.now);

        for (const date of freePlayDayDates) {
            const cookieName = `${store.state.app.name}:freePlayDay${date}-${store.state.profile.id}`;
            if (Cookies.get(cookieName)) {
                // Already viewed this dialog, go to next
                continue;
            }

            // Only showing day before
            const start = new Date(date);
            const before = sub(start, { days: 1 });

            if (appNow >= before && appNow < start) {
                shouldShowDialog.value = true;
                nextTick(() => {
                    dialog.value.showModal();
                });

                Cookies.set(cookieName, 1);
                break; // Found the active one, don't check any further
            }
        }
    }


    // Lifecycle
    onMounted(() => {
        showFreePlayDialog();
    });

return (_ctx, _cache) => {
  return (shouldShowDialog.value)
    ? (_openBlock(), _createBlock(FreePlayFridaysDialog, {
        key: 0,
        ref_key: "dialog",
        ref: dialog
      }, null, 512))
    : _createCommentVNode("", true)
}
}

}