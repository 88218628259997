import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "second-header" }
const _hoisted_2 = { class: "paragraph" }

import { ref } from 'vue';

    import { useDialog } from '@composables/dialog';
    import { useI18next } from '@composables/i18next';

    // Setup
    
export default {
  __name: 'FreePlayFridaysDialog',
  setup(__props, { expose: __expose }) {

    const rootEl = ref();

    const { close, showModal } = useDialog('free-play-fridays', rootEl);
    const { vT } = useI18next('free_play_day_fridays_modal');


    // Expose
    __expose({
        showModal,
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "rootEl",
    ref: rootEl,
    class: "free-play-fridays-dialog"
  }, [
    _withDirectives(_createElementVNode("h2", _hoisted_1, null, 512), [
      [_unref(vT), 'headline']
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_2, null, 512), [
      [_unref(vT), 'body']
    ]),
    _createElementVNode("button", {
      class: "button medium color-1",
      onClick: _cache[0] || (_cache[0] = (...args) => (_unref(close) && _unref(close)(...args)))
    }, [
      _withDirectives(_createElementVNode("span", null, null, 512), [
        [_unref(vT), 'cta']
      ])
    ])
  ], 512))
}
}

}